<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <h4 id="top">Activity Tracker</h4>
    </div>

    <div v-if="timeCards" class="card">
      <div class="rainbow-background"></div>
      <div class="table-responsive">
        <table class="card-table table">
          <thead>
            <tr>
              <th class="w-20">Week Start Date</th>
              <th class="w-20">Week End Date</th>
              <th class="w-20">Status</th>
              <th class="w-20">Total Days</th>
              <th class="w-20"></th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="timeCard in timeCards" :key="timeCard.id" :id="timeCard.id" :class="determineStatusClass(timeCard)">
            <td class="w-20">
              {{ formattedDate(timeCard.week_start_date, 1) }}
            </td>
            <td class="w-20">
              {{ formattedDate(timeCard.week_start_date, 7) }}
            </td>
            <td class="w-20">
              <b-badge :variant="determineStatus(timeCard.status)">{{ timeCard.status }}</b-badge>
            </td>
            <td class="w-20">
              {{ timeCard.calculate_total_days }}
            </td>
            <td class="w-20">
              <div class="btn-group center">
                <router-link :to="{ name: 'TimeCardShow', params: { id: timeCard.id } }" tag="button" class="btn btn-secondary" type="button">View</router-link>
                <router-link v-if="showInput(timeCard.status)" :to="{ name: 'TimeCardEdit', params: { id: timeCard.id } }" tag="button" class="btn btn-primary" type="button">Input Activity</router-link>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      <pagination :totalRows="totalRows" :page="currentPage" :numOfRecords="perPage" @onChange="onChange" />

    </div>

    <loading-overlay v-else />

  </div>
</template>

<script>
import { TimeCardService } from '@/common/services/time_card.service';
import Pagination from '@/common/components/pagination.vue';
import { FilterService } from '@/common/services/filter.service';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import HelperMethods from '@/common/mixins/helper_methods';
import JwtService from '@/common/services/jwt.service';

export default {
  name: 'TimeCardIndex',
  mixins: [HelperMethods],
  components: {
    Pagination,
    LoadingOverlay,
  },
  data() {
    return {
      timeCards: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      totalRecords: 0,
      filters: FilterService.defaultData(),
      jwt: JwtService.parseJwt(),
      isAdmin: null,
    };
  },
  created() {
    this.isAdmin = this.jwt.role === 'Admin';
    this.getTimeCards(this.currentPage, this.perPage);
  },
  methods: {
    onChange(page, perPage) {
      this.getTimeCards(page, perPage);
    },
    getTimeCards(page, perPage) {
      this.filters.user_id_eq = this.jwt.sub;
      this.filters.s = 'week_start_date desc';
      const params = FilterService.build({ page, per_page: perPage }, this.filters);
      TimeCardService.query(params).then((response) => {
        this.currentPage = parseFloat(response.headers['x-page']);
        this.totalRows = parseFloat(response.headers['x-total']);
        this.timeCards = response.data;
      });
    },
    setFilters(filters) {
      this.filters = filters;
      this.getTimeCards(this.currentPage, this.perPage);
    },
    toDate(dateStr) {
      const [year, month, day] = dateStr.split('-');
      return new Date(year, month - 1, day);
    },
    determineStatusClass(timeCard) {
      const currentDate = new Date();

      const minDate = this.toDate(timeCard.week_start_date);
      let maxDate = this.toDate(timeCard.week_start_date);
      maxDate = maxDate.setDate(maxDate.getDate() + 7);

      if (currentDate > minDate && currentDate < maxDate) {
        return 'submitted';
      }
      return 'in-progress';
    },
    showInput(status) {
      let visible = true;
      if (status === 'Approved' || status === 'Submitted' || status === 'Resubmitted') {
        visible = false;
      }
      return visible;
    },
    determineStatus(status) {
      switch (status) {
        case 'Approved':
          return 'success';
        case 'In Progress':
          return 'primary';
        case 'Submitted':
          return 'warning';
        case 'Rejected':
          return 'danger';
        case 'Resubmitted':
          return 'warning';
        default:
          return 'primary';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../styles/views/timecard";
</style>
